
.periodical-updater {
	display: inline-flex;
	align-items: center
}

.buttons {
	display: inline-flex;
	margin-right: 10px;
}
