$font-color: #000;
$font-family: AmorBold, Arial, Helvetica, sans-serif;
$font-big-size: 1.75rem;
$font-heading-color: #007e31;
$font-secondary-color: #666;
$font-secondary-color-light: #dddddd;

@font-face {
  font-family: Amor;
  src: url("font/AmorSansPro.ttf");
}

@font-face {
  font-family: AmorBold;
  src: url("font/AmorSansTextPro.ttf");
}

h1, h2 {
  color: $font-heading-color;
}

strong {
  font-family: AmorBold, Arial, Helvetica, sans-serif;
}

body {
  font-size: 1.25rem;
  line-height: 1.4rem;
  font-family: $font-family;
  color: $font-color;
  background-color: #ffffff;
}

.base-font {
  font-family: var(--bs-body-font-family) !important;
}

li {
  margin-bottom: 0.8vw;
}

.registration {
  a {
    text-decoration: none;
  }

  h3 {
    margin-bottom: 1rem;
  }

  .error {
    color: red;
    font-size: 1.5rem;
  }

  box-shadow: #ddd 0.5vw 0.75vw 2vw;
  padding: 1.5rem 2rem;
  color: $font-heading-color;
  font-family: $font-family;

  .labels {
    margin-left: 1rem;
  }

  .labels div, {
    margin-bottom: 2rem;
    color: $font-secondary-color;
    font-size: $font-big-size;
  }

  input[type=number], input[type=text], input[type=email] {
    color: $font-secondary-color;
    border: 0;
    border-bottom: 2px solid #ddd;
    padding-left: 1rem;
    font-size: $font-big-size;
    height: 4rem;
    width: 100%;
  }

  input[type=submit], .submit-button {
    width: 100%;
    border: 0;
    padding: 0.8rem;
    margin-top: 1rem;
    background-color: $font-heading-color;
    font-size: $font-big-size;
  }

  input[type=submit][disabled] {
    background-color: $font-secondary-color;
    font-size: $font-big-size;
  }

  input[type=text]::placeholder, input[type=email]::placeholder, input[type=number]::placeholder {
    color: $font-secondary-color-light;
  }
}
    

    
