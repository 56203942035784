
.state-timeline {

  .el-timeline-item__content {
    font-size: 1.3rem !important;
  }


  .el-timeline li {
    margin-bottom: 0;
  }

}
