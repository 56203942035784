body {
  background-color: #f4f5f7;

}

#app {

}

.mh-95 {
  min-height: 95vh;
}

.flex-1 {
  flex: 1;
}

.dev-size-panel {
  position: sticky;
  left: 3px;
  top: 0;
  color: orangered;
  font-weight: bold;
  z-index: 100;
}

.card.card-rounded {
  border-radius: 15px;
  box-shadow: none;
  border: none;
}

.login-form form ul.error {
  padding-left: 0;
  border-bottom: 1px solid #ff4500;
  padding-bottom: 10px;

  li {
    list-style: none;
  }
}

.gate {
  font-size: 1rem;
  &-name {
    background-color: #44c12f;
    color: #fff;
    padding: 0 3px;
  }

  &-bg {
    background-color: #fff;
    border: 1px solid #e4e4e4;
    border-radius: 15px;
    padding: 10px;
    display: inline-block;
  }
}

.datagrid .datagrid-toolbar
{
  gap: 4px;
  display: inline-flex !important;
}

.el-tabs__item {
  font-size: 1rem !important;
}

.datagrid-group-action-title {
  margin-right: 5px;
}

.row-group-actions select {
  margin-right: 5px;
}

input[type=radio], input[type=checkbox] {
  --s: 1em;     /* control the size */
  --c: #007e31; /* the active color */

  height: var(--s);
  aspect-ratio: 1;
  border: calc(var(--s)/8) solid #939393;
  padding: calc(var(--s)/8);
  background:
          radial-gradient(farthest-side,var(--c) 94%,#0000)
          50%/0 0 no-repeat content-box;
  border-radius: 50%;
  outline-offset: calc(var(--s)/10);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  font-size: inherit;
  transition: .3s;
}

input[type=checkbox] {
  border-radius: 0;

}

input[type=radio]:checked, input[type=checkbox]:checked {
  border-color: var(--c);
  background-size: 100% 100%;
}

input[type=checkbox]:checked {
  background: #4CAF50;
}

input[type=radio]:disabled, input[type=checkbox]:disabled {
  background:
          linear-gradient(#939393 0 0)
          50%/100% 20% no-repeat content-box;
  opacity: .5;
  cursor: not-allowed;
}

@media print {
  input[type=radio] {
    -webkit-appearance: auto;
    -moz-appearance: auto;
    appearance: auto;
    background: none;
  }
}

label {
  display:inline-flex;
  align-items:center;
  gap:10px;
  margin:5px 0;
  cursor: pointer;
}