
.drivers-club-form {
  .el-form-item__label, .el-button, span {
    font-size: 1.25rem;
  }

  margin-bottom: 35px;
}

.drivers-club-form.registration input[type=number] {
  height: inherit;
  font-size: 1.25rem;
}
