
.driver-detail {
  .el-descriptions__label, .el-descriptions__content, .el-descriptions__cell, .el-button {
    font-size: 1rem !important;
  }

  .el-descriptions__label {
    font-weight: normal !important;
  }

  margin-bottom: 5rem;
}
